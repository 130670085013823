// src/pages/Live.js
import React, { useState, useEffect, useContext } from "react";
import { supabase } from "../supabase";
import "./Live.css";

const LiveSupabase = () => {
  const [lapTimes, setLapTimes] = useState([]);
  const [tags, setTags] = useState({});
  const [rssTitles, setRssTitles] = useState([]);
  const [currentTime, setCurrentTime] = useState("");

  // Update clock every second
  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const formattedTime = now.toLocaleTimeString("en-GB", { hour12: false });
      setCurrentTime(formattedTime);
    }, 500);

    return () => clearInterval(interval);
  }, []);

  // Fetch tags data
  useEffect(() => {
    const fetchTags = async () => {
      const { data, error } = await supabase.from("laptimes").select("*");
      if (error) {
        console.error("Error fetching laptimes:", error);
      } else {
        // Convert array of tags to object for easier access
        const tagsObject = data.reduce((acc, tag) => {
          acc[tag.id] = tag;
          return acc;
        }, {});
        setTags(tagsObject);
      }
    };

    fetchTags();
  }, []);

  // Fetch RSS titles (remains the same)
  useEffect(() => {
    const fetchRssTitles = async () => {
      try {
        const text = ""; // await response.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, "application/xml");
        const titles = Array.from(xml.querySelectorAll("entry > title")).map((node) => node.textContent);
        setRssTitles(titles);
      } catch (error) {
        console.error("Error fetching RSS feed:", error);
      }
    };

    fetchRssTitles();
  }, []);

  // Fetch lap times data and subscribe to real-time updates
  useEffect(() => {
    const fetchLapTimes = async () => {
      const { data, error } = await supabase.from("laptimes").select("*");
      if (error) {
        console.error("Error fetching lap times:", error);
        return;
      }
      processLapTimes(data);
    };

    const subscribeToLapTimes = () => {
      const channel = supabase
        .channel("laptimes")
        .on("postgres_changes", { event: "*", schema: "public", table: "laptimes" }, (payload) => {
          // Handle real-time updates
          fetchLapTimes(); // Re-fetch data on change
        })
        .subscribe();

      return () => {
        supabase.removeChannel(channel);
      };
    };

    fetchLapTimes();
    const unsubscribe = subscribeToLapTimes();

    return unsubscribe; // Cleanup subscription on unmount
  }, [tags]);


  const processLapTimes = (data) => {
    const newLapTimes = [];
    const millis30minAgo = Date.now() - 30 * 60 * 1000;
  
    data.forEach((record) => {
      const tag = record.tag;
      if (tag !== "9992" && record["L07.rtcTime"] > millis30minAgo) {
        const name = tags[tag]?.name || tag;
        newLapTimes.push({
          id: tag,
          tag: name,
          numLaps: record["L07.numLaps"],
          distance: ((parseInt(record["L07.numLaps"]) * 250) / 1000).toFixed(2),
          location: record.location,
          lapTime: record["L07.lapTime"].toFixed(3),
          elapsedTime: record["L07.rtcTime"] - record.firstPassing, // Store elapsed time in milliseconds for sorting
          elapsedTimeFormatted: new Date(record["L07.rtcTime"] - record.firstPassing)
            .toISOString()
            .substring(12, 19),
        });
      }
    });
  
    // Sort lap times: Descending by numLaps, then ascending by elapsedTime
    newLapTimes.sort((a, b) => {
      if (b.numLaps === a.numLaps) {
        return a.elapsedTime - b.elapsedTime; // Ascending by elapsedTime
      }
      return b.numLaps - a.numLaps; // Descending by numLaps
    });
  
    setLapTimes(
      newLapTimes.map((item) => ({
        ...item,
        elapsedTime: item.elapsedTimeFormatted, // Replace elapsedTime with formatted string
      }))
    );
  };

  return (
    <div className="live">
      <div className="header" style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "0px 8px" }}>
        <h1 style={{ margin: "0px" }}>
          <span style={{ color: "#006fb7" }}>SPORT.VLAANDEREN</span> <span style={{ color: "#38b3e8" }}>GENT</span>
        </h1>
        <h1 style={{ margin: "0px", color: "#363635" }}>{currentTime}</h1>
      </div>

      <LapTimesTable lapTimes={lapTimes} />

      <footer className="footer">
        {rssTitles.length > 0 && (
          <div className="rss-marquee">
            <div>{rssTitles.join(" | ")}</div>
          </div>
        )}
        <img
          src="logo-cycling-vlaanderen-horizontal.svg"
          alt="Cycling Vlaanderen Logo"
          className="footer-logo"
        />
        <div style={{ margin: "10px 0" }}>
          <img
            src="logo-sport-vlaanderen.png"
            alt="Sport Vlaanderen Logo"
            className="footer-logo"
            style={{ padding: "0px 15px" }}
          />
        </div>
        <img src="logo-belgian-cycling.svg" alt="Belgian Cycling Logo" className="footer-logo" />
        <p className="poweredBy">Powered by technology of IDLab<br/>(UGent-imec)</p>
      </footer>
    </div>
  );
};

const LapTimesTable = ({ lapTimes }) => {
  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Naam/Tag</th>
          <th style={{ textAlign: "right" }}>Rondes</th>
          <th style={{ textAlign: "right" }}>Afstand (km)</th>
          <th style={{ textAlign: "right" }}>Rondetijd (s)</th>
          <th style={{ textAlign: "right" }}>Totale tijd</th>
        </tr>
      </thead>
      <tbody>
        {lapTimes.map((record) => (
          <tr key={record.id} id={record.id}>
            <td style={{ width: "700px", height: "75px", maxWidth: "700px", maxHeight: "75px", overflow: "hidden" }}>
              {record.tag}
            </td>
            <td style={{ textAlign: "right", width: "200px" }}>{record.numLaps}</td>
            <td style={{ textAlign: "right", width: "280px" }}>{record.distance}</td>
            <td style={{ textAlign: "right", width: "280px" }}>{record.lapTime}</td>
            <td style={{ textAlign: "right", width: "300px" }}>{record.elapsedTime}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default LiveSupabase;