import React, { useState, useEffect } from "react";
import { supabase } from "../supabase"; // Supabase client
import "./Tags.css";

const TagsSupabase = () => {
  const [tags, setTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newTag, setNewTag] = useState({ tag: "", name: "", category: "" });
  const [error, setError] = useState("");

  // Fetch tags from Supabase on component mount
  useEffect(() => {
    const fetchTags = async () => {
      const { data, error } = await supabase.from("tags").select("*");
      if (error) {
        console.error("Error fetching tags:", error);
        setError("Failed to fetch tags.");
      } else {
        setTags(data || []);
      }
    };

    fetchTags();
  }, []);

  // Add a new tag
  const handleAddTag = async () => {
    const { tag, name, category } = newTag;

    if (!tag || !name) {
      setError("All fields are required to add a tag.");
      return;
    }

    // Check if tag already exists
    if (tags.some((t) => t.tag === tag)) {
      setError("Tag already exists.");
      return;
    }

    // Save to Supabase
    const { error } = await supabase
      .from("tags")
      .insert([{ tag, name, category }]);
    if (error) {
      console.error("Error adding tag:", error);
      setError(`Error adding tag: ${error.message}`);
    } else {
      setTags([...tags, { tag, name, category }]); // Optimistic update
      setError("");
      setNewTag({ tag: "", name: "", category: "" });
    }
  };

  // Update a tag
  const handleUpdateTag = async (tag, field, value) => {
    const { error } = await supabase
      .from("tags")
      .update({ [field]: value })
      .eq("tag", tag);

    if (error) {
      console.error("Error updating tag:", error);
      setError(`Error updating tag: ${error.message}`);
    } else {
      setTags((prevTags) =>
        prevTags.map((t) => (t.tag === tag ? { ...t, [field]: value } : t))
      ); // Update locally
    }
  };

  // Delete a tag
  const handleDeleteTag = async (tag) => {
    const { error } = await supabase.from("tags").delete().eq("tag", tag);

    if (error) {
      console.error("Error deleting tag:", error);
      setError(`Error deleting tag: ${error.message}`);
    } else {
      setTags((prevTags) => prevTags.filter((t) => t.tag !== tag)); // Remove locally
    }
  };

  // Filter tags based on the search query
  const filteredTags = tags.filter(
    (tag) =>
      tag.tag.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tag.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tag.category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="tags-container">
      <h1>Manage Tags</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search tags..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="add-tag-container">
        <input
          type="text"
          placeholder="Tag (unique)"
          value={newTag.tag}
          onChange={(e) => setNewTag({ ...newTag, tag: e.target.value })}
        />
        <input
          type="text"
          placeholder="Name"
          value={newTag.name}
          onChange={(e) => setNewTag({ ...newTag, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Category"
          value={newTag.category}
          onChange={(e) => setNewTag({ ...newTag, category: e.target.value })}
        />
        <button onClick={handleAddTag}>Add Tag</button>
      </div>

      {error && <p className="error-message">{error}</p>}

      <table className="tags-table">
        <thead>
          <tr>
            <th>Tag</th>
            <th>Name</th>
            <th>Category</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTags.map((tag) => (
            <tr key={tag.tag}>
              <td>{tag.tag}</td>
              <td>
                <input
                  type="text"
                  value={tag.name}
                  onChange={(e) =>
                    handleUpdateTag(tag.tag, "name", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={tag.category}
                  onChange={(e) =>
                    handleUpdateTag(tag.tag, "category", e.target.value)
                  }
                />
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteTag(tag.tag)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          {filteredTags.length === 0 && (
            <tr>
              <td colSpan="4">No tags found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default TagsSupabase;