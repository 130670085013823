import { initializeApp } from 'firebase/app';
import { getDatabase } from "firebase/database";

/*const firebaseConfig = {
  apiKey: "AIzaSyChnnjgYYsyBuS-g3qksma9Fk3v2e3nwO4",
  authDomain: "mylapseddymerckx2.firebaseapp.com",
  databaseURL: "https://mylapseddymerckx2-default-rtdb.firebaseio.com",
  projectId: "mylapseddymerckx2",
  storageBucket: "mylapseddymerckx2.firebasestorage.app",
  messagingSenderId: "596971093828",
  appId: "1:596971093828:web:55fb4c0a4bd1800458a111",
  measurementId: "G-X4Q8CYR2X6"
};*/

const firebaseConfig = {
  apiKey: "AIzaSyCOSMtuXh2GQOMTTz9mbk4-n6rmMe-O3bM",
  authDomain: "mylapseddymerckx3.firebaseapp.com",
  projectId: "mylapseddymerckx3",
  storageBucket: "mylapseddymerckx3.firebasestorage.app",
  messagingSenderId: "206201398759",
  appId: "1:206201398759:web:5ecd951ef566aad1878942",
  measurementId: "G-F4W3MCE65B"
};

const app = initializeApp(firebaseConfig);
const database = getDatabase(app);

export { app, database };