import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import './App.css';
//import Sidenav from './Components/Sidenav';
import Live from "./Pages/Live";
import Tags from "./Pages/Tags";
import Home from "./Pages/Home";
import LiveSupabase from './Pages/LiveSupabase';
import TagsSupabase from './Pages/TagsSupabase';


function App() {

  return (
    <div className="App">
      <main style={{ width: "100%" }}>
      <Routes>
        <Route path="/" element={<LiveSupabase />}/>
        <Route path="/live_firebase" element={<Live />}/>
        <Route path="/tags" element={<TagsSupabase />}/>
        <Route path="/tags_firebase" element={<Tags />} />
        <Route path="/home" element={<Home />} />
        
      </Routes>
      </main>
     
    </div>
  );
}

export default App;
