
import React, { createContext } from "react";
import { database } from "./firebase";

export const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  return (
    <FirebaseContext.Provider value={{ database }}>
      {children}
    </FirebaseContext.Provider>
  );
};