// src/pages/Live.js
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../FirebaseContext";
import { ref, onValue } from "firebase/database";
import "./Live.css";

const Live = () => {
  const { database } = useContext(FirebaseContext);
  const [lapTimes, setLapTimes] = useState([]);
  const [tags, setTags] = useState({});
  const [rssTitles, setRssTitles] = useState([]);
  const [currentTime, setCurrentTime] = useState("");

  useEffect(() => {
    // Update clock every second
    const interval = setInterval(() => {
      const now = new Date();
      const formattedTime = now.toLocaleTimeString("en-GB", { hour12: false }); // HH:MM:SS format
      setCurrentTime(formattedTime);
    }, 500);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // Fetch tags data
  useEffect(() => {
    const tagsRef = ref(database, "tags");

    onValue(tagsRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        setTags(data);
      }
    });
  }, [database]);

  // Fetch RSS titles
  useEffect(() => {
    const fetchRssTitles = async () => {
      try {
        //const response = await fetch("https://sporza.be/nl.rss.xml");
        const text = "";//await response.text();
        const parser = new DOMParser();
        const xml = parser.parseFromString(text, "application/xml");
        const titles = Array.from(xml.querySelectorAll("entry > title")).map((node) => node.textContent);
        setRssTitles(titles);
      } catch (error) {
        console.error("Error fetching RSS feed:", error);
      }
    };

    fetchRssTitles();
  }, []);

  // Fetch lap times data
  useEffect(() => {
    const lapTimesRef = ref(database, "laptimes");

    const unsubscribe = onValue(lapTimesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        console.log(Object.keys(data).length)
        const newLapTimes = [];
        const millis30minAgo = (new Date().getTime()) - 30*60*1000
        console.log(millis30minAgo);
        Object.entries(data).forEach(([tag, record]) => {
          if (tag !== "9992" &&  record["L07"].rtcTime > millis30minAgo ) {
            const name = tags[tag]?.name || tag; // Replace tag with name if available
            newLapTimes.push({
              id: `${tag}`,
              tag: name,
              numLaps: record.L07.numLaps,
              distance: ((parseInt(record.L07.numLaps) * 250) / 1000).toFixed(2),
              location: record.location,
              lapTime: record.L07.lapTime.toFixed(3),
              elapsedTime: new Date(record["L07"].rtcTime - record.firstPassing)
                .toISOString()
                .substring(12, 19),
              updated: record.updated,
            });
          }
        });

        //console.log(newLapTimes.length)

        // Merge and reorder rows based on updates
        setLapTimes((prev) => {
          const existingIds = prev.map((row) => row.id);
          const updatedRows = [];

          // Add or update rows
          newLapTimes.forEach((newRow) => {
            const existingIndex = existingIds.indexOf(newRow);
            if (existingIndex !== -1) {
              const existingRow = prev[existingIndex];
              if (newRow.L07.rtcTime > existingRow.L07.rtcTime) {
                updatedRows[existingIndex] = newRow; // Updated
              } else {
                updatedRows[existingIndex] = existingRow; // Keep existing
              }
            } else {
              updatedRows.push(newRow); // New row
            }
          });

          //console.log(updatedRows.length)

          // Sort by number of laps
          updatedRows.sort((a, b) => b.numLaps - a.numLaps);
          return updatedRows;
        });
      }
      else{
        setLapTimes((prev) => {
          return [];
        })
      }
    });

    return () => unsubscribe(); // Cleanup on unmount
  }, [database, tags]);

  return (
    <div className="live">
      <div
        className="header"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0px 8px",
        }}
      >
        <h1 style={{ margin: "0px" }}><span style={{color : "#006fb7"}}>SPORT.VLAANDEREN</span> <span style={{color : "#38b3e8"}}>GENT</span></h1>
        <h1 style={{ margin: "0px",  color: "#363635" }}>{currentTime}</h1>
      </div>
      <LapTimesTable lapTimes={lapTimes} />
      <footer className="footer">
      {rssTitles.length > 0 && (
        <div className="rss-marquee">
          <div>
            {rssTitles.join(" | ")} {/* Combine titles into a single string */}
          </div>
        </div>
      )}
        <img
          src="logo-cycling-vlaanderen-horizontal.svg"
          alt="Cycling Vlaanderen Logo"
          className="footer-logo"
        />
        <div style={{margin: "10px 0"}}><img
          src="logo-sport-vlaanderen.png"
          alt="Sport Vlaanderen Logo"
          className="footer-logo"
          style={{padding: "0px 15px"}}
        /></div>
        <img
          src="logo-belgian-cycling.svg"
          alt="Belgian Cycling Logo"
          className="footer-logo"
        />
        <p className="poweredBy">Powered by technology of IDLab<br/>(UGent-imec)</p>
      </footer>
    </div>
  );
};

const LapTimesTable = ({ lapTimes }) => {
  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <th>Naam/Tag</th>
          <th style={{ textAlign: "right"}}>Rondes</th>
          <th style={{ textAlign: "right"}}>Afstand (km)</th>
          <th style={{ textAlign: "right"}}>Rondetijd (s)</th>
          <th style={{ textAlign: "right"}}>Totale tijd</th>
        </tr>
      </thead>
      <tbody>
        {lapTimes.map((record) => (
          <tr key={record.id} id={record.id}>
            <td style={{ width : "700px", height: "75px", "max-width": "700px", "max-height" : "75px", overflow: "hidden" }}>{record.tag}</td>
            <td style={{ textAlign: "right", width : "200px" }}>{record.numLaps}</td>
            <td style={{ textAlign: "right", width : "280px"  }}>{record.distance}</td>
            <td style={{ textAlign: "right",  width : "280px"  }}>{record.lapTime}</td>
            <td style={{ textAlign: "right",  width : "300px"  }}>{record.elapsedTime}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Live;