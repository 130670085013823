// src/pages/Tags.js
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../FirebaseContext";
import { ref, onValue, set, remove, update } from "firebase/database";
import "./Tags.css";

const Tags = () => {
  const { database } = useContext(FirebaseContext);
  const [tags, setTags] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [newTag, setNewTag] = useState({ tag: "", name: "", category: "" });
  const [error, setError] = useState("");

  useEffect(() => {
    const tagsRef = ref(database, "tags");

    // Fetch tags from Firebase
    const unsubscribe = onValue(tagsRef, (snapshot) => {
      const data = snapshot.val();
      const tagsList = data
        ? Object.entries(data).map(([tag, details]) => ({
            tag,
            name: details.name,
            category: details.category,
          }))
        : [];
      setTags(tagsList);
    });

    return () => unsubscribe();
  }, [database]);

  // Add a new tag
  const handleAddTag = () => {
    const { tag, name, category } = newTag;

    if (!tag || !name) {
      setError("All fields are required to add a tag.");
      return;
    }

    // Check if tag already exists
    if (tags.some((t) => t.tag === tag)) {
      setError("Tag already exists.");
      return;
    }

    // Save to Firebase
    set(ref(database, `tags/${tag}`), { name, category })
      .then(() => {
        setError("");
        setNewTag({ tag: "", name: "", category: "" });
      })
      .catch((err) => setError(`Error adding tag: ${err.message}`));
  };

  // Update a tag
  const handleUpdateTag = (tag, field, value) => {
    const updatedTag = { [field]: value };

    update(ref(database, `tags/${tag}`), updatedTag).catch((err) =>
      setError(`Error updating tag: ${err.message}`)
    );
  };

  // Delete a tag
  const handleDeleteTag = (tag) => {
    remove(ref(database, `tags/${tag}`)).catch((err) =>
      setError(`Error deleting tag: ${err.message}`)
    );
  };

  // Filtered tags based on the search query
  const filteredTags = tags.filter(
    (tag) =>
      tag.tag.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tag.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      tag.category.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="tags-container">
      <h1>Manage Tags</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder="Search tags..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      <div className="add-tag-container">
        <input
          type="text"
          placeholder="Tag (unique)"
          value={newTag.tag}
          onChange={(e) => setNewTag({ ...newTag, tag: e.target.value })}
        />
        <input
          type="text"
          placeholder="Name"
          value={newTag.name}
          onChange={(e) => setNewTag({ ...newTag, name: e.target.value })}
        />
        <input
          type="text"
          placeholder="Category"
          value={newTag.category}
          onChange={(e) => setNewTag({ ...newTag, category: e.target.value })}
        />
        <button onClick={handleAddTag}>Add Tag</button>
      </div>

      {error && <p className="error-message">{error}</p>}

      <table className="tags-table">
        <thead>
          <tr>
            <th>Tag</th>
            <th>Name</th>
            <th>Category</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredTags.map((tag) => (
            <tr key={tag.tag}>
              <td>{tag.tag}</td>
              <td>
                <input
                  type="text"
                  value={tag.name}
                  onChange={(e) =>
                    handleUpdateTag(tag.tag, "name", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={tag.category}
                  onChange={(e) =>
                    handleUpdateTag(tag.tag, "category", e.target.value)
                  }
                />
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => handleDeleteTag(tag.tag)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
          {filteredTags.length === 0 && (
            <tr>
              <td colSpan="4">No tags found.</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Tags;