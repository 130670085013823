// src/pages/Home.js
import React, { useState, useEffect, useContext } from "react";
import { FirebaseContext } from "../FirebaseContext";
import { ref, onValue } from "firebase/database";
import "./Home.css"

const Home = () => {
  const { database } = useContext(FirebaseContext);
  const [lapTimes, setLapTimes] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState({
    corner_split: true,
    straight_split: true,
    sprint_split: true,
  });


  useEffect(() => {
    // Reference to the "laptimes" node in the database
    const lapTimesRef = ref(database, "laptimes");

    // Listen for changes in the database
    const unsubscribe = onValue(lapTimesRef, (snapshot) => {
      const data = snapshot.val();
      if (data) {
        const newLapTimes = [];
        Object.entries(data).forEach(([tag, record]) => {
          if (record.location === "L07") { // Team pursuit
            let new_row = {
              id: `${tag}-${record[record.location].numLaps}`, 
              tag,
              numLaps: record[record.location].numLaps,
              location: record.location,
              h1_corner : (record["L07"].lapTime - (record["L07"].rtcTime - record["L04"].rtcTime)/1000).toFixed(3),
              h2_corner : ((record["L07"].rtcTime - record["L04"].rtcTime)/1000).toFixed(3),
              lapTime: record[record.location].lapTime.toFixed(3),
              updated: record.updated,
              type: "corner_split"
            };
            if(new_row.h1_corner > 0 && new_row.h2_corner > 0){
              newLapTimes.push(new_row);
            }
          }
          else if(record.location === "L01"){ // Sprint
            let new_row = {
              id: `${tag}-${record[record.location].numLaps}`, 
              tag,
              numLaps: record[record.location].numLaps,
              location: record.location,
              h1 : null,
              h2 : null,
              m0_m200 : (record["L01"].lapTime - (record["L01"].rtcTime - record["L05"].rtcTime)/1000 - (record["L05"].rtcTime - record["L02"].rtcTime)/1000).toFixed(3),
              m200_m100 : ((record["L05"].rtcTime - record["L02"].rtcTime)/1000).toFixed(3),
              m100_m0 : ((record["L01"].rtcTime - record["L05"].rtcTime)/1000).toFixed(3),
              lapTime: record[record.location].lapTime.toFixed(3),
              updated: record.updated,
              type: "sprint_split"
            };
            if(new_row.m0_m200 > 0 && new_row.m200_m100 > 0 && new_row.m100_m0 > 0){
              newLapTimes.push(new_row);
            }
          }
          else if(record.location === "L02"){ // PAV
            let new_row = {
              id: `${tag}-${record[record.location].numLaps}`,
              tag,
              numLaps: record[record.location].numLaps,
              location: record.location,
              h1_straight : (record["L02"].lapTime - (record["L02"].rtcTime - record["L06"].rtcTime)/1000).toFixed(3),
              h2_straight : ((record["L02"].rtcTime - record["L06"].rtcTime)/1000).toFixed(3),
              lapTime: record[record.location].lapTime.toFixed(3),
              updated: record.updated,
              type: "straight_split"
            };
            if(new_row.h1_straight > 0 && new_row.h2_straight > 0){
              newLapTimes.push(new_row);
            }
          }
        });

        

        // Merge new rows into the state and limit to 25 rows
        setLapTimes((prev) => {
          const merged = [...newLapTimes, ...prev];
          const uniqueRows = merged.filter(
            (row, index, self) => index === self.findIndex((r) => r.id === row.id)
          );
          uniqueRows.sort(
            (a, b) => new Date(b.updated) - new Date(a.updated)
          );
          return uniqueRows.slice(0, 20); // Keep only the latest 25 unique rows
        });
      }
    });

    

    return () => unsubscribe(); // Cleanup on unmount
  }, [database]);

  const toggleColumn = (column) => {
    setVisibleColumns((prev) => ({ ...prev, [column]: !prev[column] }));
  };

  return (
    <div>
      <h1>Live MyLaps Results Eddy Merckx Cyling Center, Ghent Belgium</h1>
      <div>
        <label>
          <input
            type="checkbox"
            checked={visibleColumns.corner_split}
            onChange={() => toggleColumn("corner_split")}
          />
          Bochten
        </label>
        <label>
          <input
            type="checkbox"
            checked={visibleColumns.straight_split}
            onChange={() => toggleColumn("straight_split")}
          />
          Straights
        </label>
        <label>
          <input
            type="checkbox"
            checked={visibleColumns.sprint_split}
            onChange={() => toggleColumn("sprint_split")}
          />
          Sprint
        </label>
      </div>
      <LapTimesTable lapTimes={lapTimes} visibleColumns={visibleColumns} />
    </div>
  );
};



const LapTimesTable = ({ lapTimes, visibleColumns }) => {
  // Identify the latest entry and the fastest lap
  const mostLaps = lapTimes.reduce(
    (min, row) => (row.numLaps > min.numLaps ? row : min),
    lapTimes[0] || {}
  );
  const fastestLap = lapTimes.reduce(
    (min, row) => (row.lapTime < min.lapTime ? row : min),
    lapTimes[0] || {}
  );

  return (
    <table>
      <thead>
        <tr>
          <th>Tag</th>
          <th>Laps</th>
          {visibleColumns.corner_split && <th>H1 (Bocht 1+2)</th>}
          {visibleColumns.corner_split && <th>H2 (Bocht 3+4)</th>}
          {visibleColumns.straight_split && <th>H1 (Home straight)</th>}
          {visibleColumns.straight_split && <th>H2 (Back straight)</th>}
          {visibleColumns.sprint_split && <th>Start &gt; 200m</th>}
          {visibleColumns.sprint_split && <th>200m &gt; 100m</th>}
          {visibleColumns.sprint_split && <th>100m &gt; Finish</th>}
          <th>LapTime</th>
        </tr>
      </thead>
      <tbody>
        {lapTimes.map((record) => (
          <tr
            key={record.id}
            style={{
              backgroundColor:
              record.id === mostLaps.id
                  ? "lightblue" // Dark gray for the latest entry
                  : record.id === fastestLap.id
                  ? "lightgreen" // Light green for the fastest lap
                  : "white", // Default background
            }}
          >
            <td>{record.tag}</td>
            <td>{record.numLaps}</td>
            {visibleColumns.corner_split && <td>{record.h1_corner}</td>}
            {visibleColumns.corner_split && <td>{record.h2_corner}</td>}
            {visibleColumns.straight_split && <td>{record.h1_straight}</td>}
            {visibleColumns.straight_split && <td>{record.h2_straight}</td>}
            {visibleColumns.sprint_split && <td>{record.m0_m200}</td>}
            {visibleColumns.sprint_split && <td>{record.m200_m100}</td>}
            {visibleColumns.sprint_split && <td>{record.m100_m0}</td>}
            <td>{record.lapTime}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Home;
